<template>
    <div class="page">
        <el-row type="flex" justify="center">
            <el-col
                :xl="11"
                style="height: 100vh; max-height: 927px; overflow-y: auto;"
            >
                <div class="left_box">
                    <!-- logo -->
                    <el-image :src="logo" fit="none"></el-image>
                    <!-- form -->
                    <template v-if="havePhone">
                        <el-form
                            ref="form"
                            :rules="rules"
                            label-position="top"
                            :model="form"
                            label-width="80px"
                            style="margin-top: 50px;"
                        >
                            <el-form-item label="企业名称" prop="name">
                                <el-input v-model="form.name"></el-input>
                            </el-form-item>
                            <el-form-item label="行业类型" prop="industry">
                                <el-input v-model="form.industry"></el-input>
                            </el-form-item>
                            <el-form-item label="人员规模" prop="staff_num">
                                <el-input v-model="form.staff_num"></el-input>
                            </el-form-item>
                            <el-form-item label="密码" prop="password">
                                <el-input
                                    type="password"
                                    v-model="form.password"
                                    show-password
                                ></el-input>
                            </el-form-item>
                            <el-form-item label="验证码" prop="phone_code">
                                <el-input v-model="form.phone_code"></el-input>
                                <span
                                    class="send_code_span"
                                    :class="{ couting: sec > 0 }"
                                    @click="toSendCode"
                                    >{{
                                        sec > 0
                                            ? sec + "秒后可重新获取"
                                            : "获取验证码"
                                    }}</span
                                >
                            </el-form-item>
                        </el-form>
                    </template>
                    <template v-else>
                        <el-form
                            ref="form2"
                            :rules="rules2"
                            label-position="top"
                            :model="form2"
                            label-width="80px"
                            style="margin-top: 50px;"
                        >
                            <el-form-item label="手机号" prop="phone">
                                <el-input
                                    v-model="form2.phone"
                                    placeholder="请输入手机号"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                    </template>

                    <!-- 登录 -->
                    <div class="register">
                        已有账号？<span
                            style="cursor: pointer;"
                            @click="$router.push({ name: 'Login' })"
                            >立即登录</span
                        >
                    </div>

                    <div
                        class="login_btn no_select"
                        :class="{ visible: !isRegister }"
                        v-loading="isRegister"
                        element-loading-background="rgba(0, 0, 0, 0.8)"
                        @click="toRegister"
                    >
                        {{ havePhone ? "立即注册" : "下一步"
                        }}<el-image
                            :src="require('@/assets/login/enter.png')"
                            fit="none"
                            style="width: 15px; height: 15px;"
                        ></el-image>
                    </div>
                </div>
            </el-col>
            <el-col :xl="13">
                <el-image
                    style="width: 100%; height: 100vh; min-height: 875px;"
                    :src="require('@/assets/login/bg.png')"
                    fit="cover"
                ></el-image>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    data() {
        return {
            isRegister: false,
            sec: 0,
            havePhone: false,
            form2: {
                phone: "",
            },
            rules2: {
                phone: [
                    {
                        required: true,
                        message: "请填写手机号",
                        trigger: "blur",
                    },
                    {
                        len: 11,
                        message: "请输入正确的手机号",
                        trigger: "blur",
                    },
                ],
            },
            form: {
                phone_code: "",
                name: "",
                industry: "",
                staff_num: "",
                password: "",
            },
            rules: {
                phone_code: [
                    {
                        required: true,
                        message: "请填写验证码",
                        trigger: "blur",
                    },
                ],
                name: [
                    {
                        required: true,
                        message: "请填写企业名称",
                        trigger: "blur",
                    },
                ],
                industry: [
                    {
                        required: true,
                        message: "请填写行业类型",
                        trigger: "blur",
                    },
                ],
                staff_num: [
                    {
                        required: true,
                        message: "请填写人员规模",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请填写密码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        message: "请填写至少6位数的密码",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    computed: {
        logo() {
            return this.$store.getters["users/owner"].logo;
        },
    },
    methods: {
        ...mapActions("users", [
            "login",
            "registerFdd",
            "verifyPhone",
            "register",
            "sendCode",
        ]),

        /**
         * 发送验证码
         */
        toSendCode() {
            if (this.sec > 0) return;
            this.sec = 60;
            this.couter = setInterval(() => {
                this.sec--;
                if (this.sec <= 0) {
                    clearInterval(this.couter);
                }
            }, 1000);
            console.log(this.form2.phone);
            this.sendCode({ mobile: this.form2.phone, type: 1 })
                .then((res) => {
                    console.log(res);
                    this.$alert("短信已下发，稍后请留意短信。", "提示", {
                        confirmButtonText: "确定",
                        callback: () => {},
                    });
                })
                .catch((e) => {
                    console.error(e);
                    this.$message.error(e.msg);
                });
        },

        /**
         * 验证&注册
         */
        toRegister() {
            if (!this.havePhone) {
                this.$refs.form2.validate((valid) => {
                    if (valid) {
                        this.verifyPhone({ mobile: this.form2.phone })
                            .then((res) => {
                                console.log(res);
                                this.havePhone = true;
                            })
                            .catch((e) => {
                                console.error(e);
                                this.$message.error(e.msg);
                            });
                    }
                });
                return;
            }

            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.isRegister = true;
                    this.register({
                        ...this.form,
                        mobile: this.form2.phone,
                        owner_id: this.$store.getters["users/owner"].id,
                    })
                        .then((res) => {
                            this.isRegister = false;
                            console.log(res);
                            this.$alert(res.data.msg, "注册成功", {
                                confirmButtonText: "确定",
                                callback: () => {
                                    this.$router.push({ name: "Login" });
                                },
                            });
                        })
                        .catch((e) => {
                            this.isRegister = false;
                            console.error(e);
                            this.$message.error(e.msg);
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.page {
    background: linear-gradient(to bottom, #282a2c, #06080a);
    height: 100vh;
    min-width: 680px;
    overflow: hidden;
}

.left_box {
    padding-top: 80px;
    margin: 0 auto;
    width: max-content;

    .login_nav {
        color: #9c9c9c;
        font-size: 22px;
        display: flex;
        align-items: flex-end;
        margin-top: 190px;

        > div {
            margin-right: 40px;
            padding-bottom: 17px;
            cursor: pointer;
        }

        > div.active {
            position: relative;
            font-size: 28px;
            letter-spacing: 2px;
            color: #ffffff;

            &::after {
                content: "";
                width: 30px;
                height: 4px;
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #e50112;
            }
        }
    }

    .register {
        color: #505051;
        font-size: 16px;
        margin-top: 30px;

        span {
            color: #4db9d5;
            text-decoration: underline;
        }
    }

    .login_btn {
        width: 540px;
        height: 80px;
        background-color: #4db9d5;
        border-radius: 5px;
        font-size: 20px;
        color: #ffffff;
        line-height: 80px;
        text-align: center;
        margin: 30px 0;

        &.visible:active {
            filter: brightness(1.2);
        }

        .el-image {
            margin-left: 10px;
        }
    }
}

.el-form-item {
    margin-bottom: 10px;
}

.send_code_span {
    cursor: pointer;
    color: #0080ff;
    position: absolute;
    top: -50px;
    right: 0;

    &.couting {
        cursor: unset;
        color: gray;
    }
}
</style>
